.root {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 510;
    padding: 30px 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
}

.cookie_container {
    justify-content: space-between;
    align-items: center;
}

.text {
    padding-right: 100px;
}

.text div:first-child {
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
}

.buttons_wrapper button:first-child {
    margin-right: 15px;
}

@media (max-width: 768px) {
    .text {
        padding: 0;
    }

    .buttons_wrapper {
        margin-top: 15px;
    }
}